<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ThePreloader',
  setup () {
    return {
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  z-index: $z-preloader;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($c-pink, 50%);
}

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  animation: rotate 2s linear infinite;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke: $c-deep-red;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
